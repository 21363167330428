<template>
  <div>
    <b-card title="Zamanlanmış görev devreye alındı..">
      <img src="@/assets/home.jpg" style="width: 40%" alt="" />
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style></style>
